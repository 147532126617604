import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const CaramelPage = () => (
	<Layout headerColor='yellow'>
      <SEO title="Caramel" />

      <h1>Caramel Page Coming Soon</h1>
  	</Layout>
)

export default CaramelPage;